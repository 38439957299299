export default {
  colors: {
    brand: '#361647',
    'brand-dark': '#7C2F8B',
    cta: '#E94816',
    support: '#37b4cb',
    success: '#8EBE55',
    black: '#323232',
    white: '#FFFFFF',
    red: '#FF0000',
    grey: '#666666',
    greyMedium: '#aaaaaa',
    greyText: '#97a2bb',
    lightGrey: '#efedf0'
  },
  spacing: {
    xs: '0.25rem',
    s: '0.5rem',
    m: '1rem',
    l: '2rem',
    xl: '3rem',
    xxl: '4rem',
    xxl2: '8rem',
  },
  breakpoints: {
    xs: '20em', // 320 pixels
    s: '30em', // 480 pixel
    m: '48em', // 768 pixels
    l: '60em', // 960 pixels
    xl: '80em', // 1280 pixels
  },
  typography: [
    13 / 16,
    16 / 16,
    18 / 16, //h6
    20 / 16, //h5
    22 / 16, //h4
    24 / 16, //h3
    30 / 16, //h2
    36 / 16, //h1
  ],
  screenreader: `
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  `,
};
