/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require('react');
const { ThemeProvider, createGlobalStyle } = require('styled-components');
const { Normalize } = require('styled-normalize');

require('typeface-source-sans-pro');
const theme = require('./src/theme.js');
const typography = require('./src/typography.js');
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
		font-family: 'Source Sans Pro', sans-serif;
		color: ${theme.default.colors.black};
		background-color: ${theme.default.colors.lightGrey};
  }
	.menu-open {
		max-height: 100vh;
		overflow: hidden;
	}
	@media screen and (max-width: 480px) {
		.menu-open {
			overflow:visible;
			max-height:none;
		}
	}
	* {
		scroll-behavior: smooth;
		box-sizing: border-box;
  }
  h1 {
    ${typography.default.h1}
  }
  h2 {
    ${typography.default.h2}
  }
  h3 {
    ${typography.default.h3}
  }
  h4 {
    ${typography.default.h4}
  }
  h5 {
    ${typography.default.h5}
  }
  p {
    ${typography.default.paragraph}
    color: ${theme.default.colors.brand};
  }

  @-webkit-keyframes SlideIn {
   0% {
     opacity: 0;
     transform: translate3d(-300px, 0, 0);
   }

   40% {
     opacity: 1;
     transform: translate3d(10px, 0, 0);
   }

   50% {
     transform: translate3d(-10px, 0, 0);
   }

   to {
     transform: translate3d(0, 0, 0);
   }
 }
`;
exports.wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme.default}>
      <React.Fragment>
        <Normalize />
        <GlobalStyle />

        {element}
      </React.Fragment>
    </ThemeProvider>
  );
};
