// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("d:\\Install\\agent\\_work\\4\\s\\src\\templates\\page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-404-js": () => import("d:\\Install\\agent\\_work\\4\\s\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "d:\\Install\\agent\\_work\\4\\s\\.cache\\data.json")

