import Theme from './theme';

export default {

  h1: `
    color: ${Theme.colors.white};
    font-weight: bold;
    font-style: italic;
    font-size: 80px;
  `,

  h2: `
    color: ${Theme.colors.brand};
    font-weight: bold;
    font-style: italic;
    font-size: 56px;
    margin: .6em 0;
  `,

  h2_subtitle: `
    color: ${Theme.colors.greyText};
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 3.4px;
    text-transform: uppercase;
    font-style: normal;
  `,

  h3: `
    color: ${Theme.colors.white};
    font-weight: bold;
    font-style: italic;
    font-size: 46px;
    text-align: center;
    line-height: 25px;
    letter-spacing: .7px;
  `,

  h4: `
    color: ${Theme.colors.brand};
    font-weight: bold;
    font-style: italic;
    font-size: 24px;
    line-height: 28px;
  `,

  h4_alt: `
    color: ${Theme.colors.white};
    font-weight: bold;
    font-style: italic;
    font-size: 24px;
    line-height: 25px;
    letter-spacing: .7px;
  `,

  h4_subtitle: `
    color: ${Theme.colors.white};
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
  `,

  h4_subtitle_alt: `
    color: ${Theme.colors.white};
    font-size: 16px;
    line-height: 25px;
  `,

  h5: `
    color: ${Theme.colors.white};
    font-weight: bold;
    font-style: italic;
    font-size: 14px;
    line-height: 25px;
  `,

  paragraph: `
    color: ${Theme.colors.black};
    font-size: 18px;
    line-height: 33px;
  `,

  paragraph_alt: `
    color: ${Theme.colors.greyText};
    font-size: 18px;
    line-height: 27px;
  `,

  button: `
    color: ${Theme.colors.white};
    font-weight: bold;
    font-style: italic;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: .3px;
  `
}